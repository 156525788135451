:root {
  --green: #17d0a1;
}

.btn {
  padding: 15px;
}

.agree-btn {
  background-color: #3de9be;
  border-color: #3de9be;
  color: white;
  // font-weight: bolder;
  // text-transform: uppercase;
  // border-radius: 10px;

  &:hover{
    background-color: #12a27e;
    color: #fff;
  }
}

.back-btn {
  margin-right: 5px;
  background-color: #c6fff1;
  border-color: #c6fff1;
  color: #3de9be;
  font-weight: bolder;
  text-transform: uppercase;
  border-radius: 10px;
}

.white-btn {
  margin-right: 5px;
  background-color: white;
  border-color: white;
  color: #3de9be;
  font-weight: bolder;
  text-transform: uppercase;
  border-radius: 10px;
  bottom: 15px;
  right: 15px;
  position: absolute;
}

.display-flex {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

body {
  background-color: #f6faff;
  font-family: 'Lato', sans-serif !important;
}

.btn {
  padding: 15px;
}